<template>
  <div class="api-key__popup-root">
    <div class="api-key__popup-bg" @click="closeChangeApiKeyPopup"/>
    <div class="api-key__popup">
      <div class="api-key__title"> {{ $t('apiKeyChangePopup.title') }} </div>
      <div class="api-key__input-block">
        <label class="api-key__input-label"> {{ $t('apiKeyChangePopup.thisApi') }} </label>
        <input
          class="api-key__input" disabled v-model="apiKeyValue"
        />
      </div>
      <div class="api-key__input-block">
        <label class="api-key__input-label"> {{ $t('apiKeyChangePopup.passedPasswordInput') }} </label>
        <div class="api-key__input">
          <input
              v-model="passedPassword"
              :type="passedPasswordType"
              :placeholder="$t('apiKeyChangePopup.passedPasswordInput')"
              class="api-key__input"
          />
          <img
              v-if="passedPasswordIcon"
              @click="toggleInputType('passedPassword')"
              class="api-key__input-image"
              src="~/assets/img/inputs/passlock-icon.svg"
          />
          <img
              v-if="!passedPasswordIcon"
              @click="toggleInputType('passedPassword')"
              class="api-key__input-image"
              src="~/assets/img/inputs/passUnlock-icon.svg"
          />
        </div>
      </div>
      <button class="api-key__submit-button btn primary" @click="changeApi">{{ $t('button.change') }}</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import {changeApiKeyPopupStore, changeApiKeyPopupStore as store} from '~/stores/changeApiKey-popup-store';
import {useUserAuth} from "~/composables/user/useUserAuth";
import {successPopupStore} from "~/stores/success-popup-store";
const { $i18n, $toast } = useNuxtApp();
const { closeChangeApiKeyPopup } = changeApiKeyPopupStore();
const { openSuccessPopup } = successPopupStore();

const {
  currentUser,
  updateUserMe,
  userChangeApiKey
} = useUserAuth();

const passedPassword = ref(null);

async function changeApi () {
  let body: any = {
    passedPassword: passedPassword.value
  }

  const res = await userChangeApiKey(body);

  if (res && res.status === "ok") {
    //$toast.success('ApiKey успешно изменен');
    await updateUserMe()
    closeChangeApiKeyPopup();
    openSuccessPopup();
  }
}

const apiKeyValue = computed(() => {
  return useUserAuth().currentUser?.api_token || '';
})

const passedPasswordType = ref('password')

const toggleInputType = function(inputName: any) {
  if (inputName === 'passedPassword' && passedPasswordType.value === 'password') {
    return passedPasswordType.value = 'text';
  }
  if (inputName === 'passedPassword' && passedPasswordType.value === 'text') {
    return passedPasswordType.value = 'password';
  }
}

const passedPasswordIcon = computed(() => {
  return passedPasswordType.value === 'password';
})
</script>

<style scoped>
.api-key__popup-root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  z-index: 997;
  align-items: center;
  padding: 15% 0;
  //border-radius: 24px;
}
.api-key__popup-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.75);
  transition: 0.5s all;
}
.api-key__popup {
  background: white;
  width: 30%;
  border-radius: 24px;
  padding: 42px;
  display: flex;
  flex-direction: column;
  transition: 0.5s all;
  z-index: 1;
  //overflow: hidden;
  box-shadow: 0px 4px 4px 0px #00000040;
  gap: 30px;
}
.api-key__title {
  color: #333333;
  font-size: 40px;
  font-weight: 600;
  line-height: 46.88px;
  text-align: center;
  padding-bottom: 2px;
}
.api-key__input-block {
  display: flex;
  flex-direction: column;
  //align-items: center;
}
.api-key__input-label {
  color: #666666;
  font-size: 18px;
  line-height: 21.09px;
  font-weight: 600;
  text-align: center;
}
.api-key__input {
  position: relative;
  border-radius: 12px;
}
.api-key__submit-button {
  display: flex;
  width: 100%;
}
.api-key__input-image {
  position: absolute;
  top: 30%;
  right: 2%;
  opacity: 30%;
  cursor: pointer;
}
@media screen and (max-width: 1600px) {
  .api-key__popup {
    width: 50%;
  }
}
@media screen and (max-width: 859px) {
  .api-key__popup {
    width: 70%;
  }
}
@media screen and (max-width: 640px) {
  .api-key__popup {
    width: 90%;
  }
  .api-key__title {
    font-size: 25px;
    line-height: 27.38px;
    padding: 0;
  }
  .api-key__input-block {
    align-items: flex-start;
  }
  .api-key__input-label {
    font-size: 16px;
    line-height: 18.75px;
  }
}
</style>
